import { Flex, Stack, useMediaQuery } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default HomePlusMovil;

function HomePlusMovil() {
  const [isLargerThan766] = useMediaQuery('(min-width: 766px)');

  return (
    <Flex align={'center'} justify={'center'} minH={isLargerThan766?10:"90vh"}>
      <Stack
        mx={'auto'}
        px={isLargerThan766 ? 10 : 0}
        py={isLargerThan766 ? 5 : 0}
      >
        <CustomNextImage
          src={
            isLargerThan766
              ? '/imgs/plus_movil/banner.jpg'
              : '/imgs/plus_movil/banner_mobile.jpg'
          }
          alt="Banner Web"
          width={isLargerThan766 ? '1920px' : '810px'}
          height={isLargerThan766 ? '783px' : '1260px'}
        />
      </Stack>
    </Flex>
  );
}
