
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import HomePlusMovil from '@modules/PlusMovil/home';
// import PrizesProvider from '@context/prizesContext';
// import StagesProvider from '@context/stagesContext';
import { ThemeNames } from '@context/themeContext';
import { useTheme } from '@hooks/useTheme';
import Layout from '@layout';
import LandingPageLayout from '@layout/LandingPagePlusMovil';
// import Default from '@modules/Default';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';
export default HomePage;
HomePage.getLayout = function getLayout(page: ReactElement) {
    return (<LandingPageLayout>
      <Layout>{page}</Layout>
    </LandingPageLayout>);
};
function HomePage() {
    const router = useRouter();
    const { setTheme } = useTheme();
    useEffect(() => {
        setTheme(ThemeNames.default);
    }, []);
    useEffect(() => {
        if (router.query != null && router.query.redirectUrl) {
            let redirectUrl = String(router.query.redirectUrl);
            if (redirectUrl != undefined &&
                redirectUrl != '' &&
                redirectUrl != null) {
                router.push(Buffer.from(redirectUrl, 'base64').toString('ascii'));
            }
        }
    }, []);
    return <HomePlusMovil />;
}

    async function __Next_Translate__getStaticProps__191285a8e84__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191285a8e84__ as getStaticProps }
  